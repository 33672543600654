<template>
  <div class="fixed left-0 top-0 z-50 h-0.25 w-full bg-wedges-orange-600" />
  <div class="flex items-center justify-between space-x-2 bg-wedges-orange-600 px-5 py-2 text-white">
    <div>
      Test mode is currently enabled.
      <a
        href="https://docs.lemonsqueezy.com/help/getting-started/test-mode/"
        target="_blank"
        class="ml-1 underline hover:opacity-70"
      >
        Help↗
      </a>
    </div>
    <slot />
    <div v-if="isCurrentStoreOwnerAndLiveModeDisabled">
      <a :href="route('settings.general.activate')" class="btn rounded-xl px-2.5"> Activate your store </a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isCurrentStoreOwnerAndLiveModeDisabled() {
      return (
        this.currentUser &&
        this.currentStore &&
        this.currentStore.activation_status === 'action_required' &&
        this.currentStore.owner_id === this.currentUser.id
      )
    },
  },

  methods: {
    route(...args) {
      return this.$route(...args)
    },
  },
}
</script>
