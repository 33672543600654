let ThemesRaw = {
  vanilla: {
    background: '#F7F7F8',
    logo: '#121217',
    headings: '#121217',
    primaryText: '#121217',
    secondaryText: '#6C6C89',
    links: '#7047EB',
    borders: '#D1D1DB',
    checkbox: '#8A8AA3',
    activeState: '#7047EB',
    button: '#7047EB',
    buttonText: '#FFFFFF',
    juicy: '#FFC233',
    termsPrivacy: '#6C6C89',
    footer: '#F7F7F8',
    footerText: '#6C6C89',
    header: '#F7F7F8',
    focusState: '#7047EB',
  },
  blackberry: {
    background: '#141414',
    logo: '#FFFFFF',
    headings: '#FFFFFF',
    primaryText: '#CCCCCC',
    secondaryText: '#808080',
    links: '#8E68FF',
    borders: '#333333',
    checkbox: '#555555',
    activeState: '#8E68FF',
    button: '#7047EB',
    buttonText: '#FFFFFF',
    juicy: '#FFC233',
    termsPrivacy: '#808080',
    footer: '#141414',
    footerText: '#808080',
    header: '#141414',
    focusState: '#8E68FF',
  },
  blueberry: {
    background: '#5423E7',
    logo: '#FFFFFF',
    headings: '#FFFFFF',
    primaryText: '#F7F6FD',
    secondaryText: '#D0BFFF',
    links: '#FFC233',
    borders: '#825DF1',
    checkbox: '#9274EF',
    activeState: '#FFC233',
    button: '#5423E7',
    buttonText: '#FFFFFF',
    juicy: '#FFC233',
    termsPrivacy: '#D0BFFF',
    footer: '#5423E7',
    footerText: '#D0BFFF',
    header: '#5423E7',
    focusState: '#5423E7',
  },
  lime: {
    background: '#49FFC8',
    logo: '#121217',
    headings: '#121217',
    primaryText: '#121217',
    secondaryText: '#121217',
    links: '#121217',
    borders: '#3FD7A9',
    checkbox: '#30A883',
    activeState: '#121217',
    button: '#121217',
    buttonText: '#FFFFFF',
    juicy: '#121217',
    termsPrivacy: '#121217',
    footer: '#49FFC8',
    footerText: '#121217',
    header: '#49FFC8',
    focusState: '#121217',
  },
  kiwi: {
    background: '#F7F7F8',
    logo: '#121217',
    headings: '#121217',
    primaryText: '#121217',
    secondaryText: '#6C6C89',
    links: '#1E874C',
    borders: '#D1D1DB',
    checkbox: '#8A8AA3',
    activeState: '#1E874C',
    button: '#26A95F',
    buttonText: '#FFFFFF',
    juicy: '#FFC233',
    termsPrivacy: '#6C6C89',
    footer: '#F7F7F8',
    footerText: '#6C6C89',
    header: '#F7F7F8',
    focusState: '#1E874C',
  },
  lemon: {
    background: '#FFC233',
    logo: '#121217',
    headings: '#121217',
    primaryText: '#121217',
    secondaryText: '#121217',
    links: '#121217',
    borders: '#D09F2D',
    checkbox: '#D09F2D',
    activeState: '#121217',
    button: '#121217',
    buttonText: '#FFFFFF',
    juicy: '#121217',
    termsPrivacy: '#121217',
    footer: '#121217',
    footerText: '#888888',
    header: '#FFC233',
    focusState: '#121217',
  },
}

ThemesRaw.vanilla.storefront = {
  ...ThemesRaw.vanilla,
  background: '#FFFFFF',
  header: '#F7F7F8',
  focusState: '#7047EB',
}
ThemesRaw.blackberry.storefront = {
  ...ThemesRaw.blackberry,
  background: '#000000',
  header: '#141414',
  focusState: '#8E68FF',
}
ThemesRaw.blueberry.storefront = {
  ...ThemesRaw.blueberry,
  background: '#FFFFFF',
  header: '#5423E7',
  logo: '#121217',
  headings: '#121217',
  primaryText: '#121217',
  secondaryText: '#6C6C89',
  links: '#5423E7',
  borders: '#D1D1DB',
  focusState: '#5423E7',
}
ThemesRaw.lime.storefront = {
  ...ThemesRaw.lime,
  background: '#FFFFFF',
  header: '#49FFC8',
  borders: '#D1D1DB',
  focusState: '#121217',
  juicy: '#FFC233',
}
ThemesRaw.kiwi.storefront = {
  ...ThemesRaw.kiwi,
  background: '#FFFFFF',
}
ThemesRaw.lemon.storefront = {
  ...ThemesRaw.lemon,
  background: '#FFFFFF',
  borders: '#D1D1DB',
  juicy: '#FFC233',
}

ThemesRaw.vanilla.overlay = {
  ...ThemesRaw.vanilla,
}
ThemesRaw.blackberry.overlay = {
  ...ThemesRaw.blackberry,
  termsPrivacy: '#808080',
}
ThemesRaw.blueberry.overlay = {
  ...ThemesRaw.blueberry,
  termsPrivacy: '#D0BFFF',
  links: '#5423E7',
}
ThemesRaw.lime.overlay = {
  ...ThemesRaw.lime,
  termsPrivacy: '#121217',
}
ThemesRaw.kiwi.overlay = {
  ...ThemesRaw.kiwi,
  background: '#FFFFFF',
  termsPrivacy: '#6C6C89',
}
ThemesRaw.lemon.overlay = {
  ...ThemesRaw.lemon,
  termsPrivacy: '#121217',
}

ThemesRaw.vanilla.email = {
  ...ThemesRaw.vanilla,
  background: '#FFFFFF',
  borders: '#121217',
}
ThemesRaw.blackberry.email = {
  ...ThemesRaw.blackberry,
  background: '#000000',
  borders: '#CCCCCC',
}
ThemesRaw.blueberry.email = {
  ...ThemesRaw.blueberry,
  background: '#FFFFFF',
  headings: '#121217',
  primaryText: '#121217',
  secondaryText: '#6C6C89',
  links: '#5423E7',
  borders: '#121217',
}
ThemesRaw.lime.email = {
  ...ThemesRaw.lime,
  background: '#FFFFFF',
  borders: '#121217',
}
ThemesRaw.kiwi.email = {
  ...ThemesRaw.kiwi,
  background: '#FFFFFF',
  borders: '#121217',
}
ThemesRaw.lemon.email = {
  ...ThemesRaw.lemon,
  borders: '#121217',
  juicy: '#FFC233',
}

ThemesRaw.vanilla.portal = {
  ...ThemesRaw.vanilla,
}
ThemesRaw.blackberry.portal = {
  ...ThemesRaw.blackberry,
}
ThemesRaw.blueberry.portal = {
  ...ThemesRaw.blueberry,
}
ThemesRaw.lime.portal = {
  ...ThemesRaw.lime,
}
ThemesRaw.kiwi.portal = {
  ...ThemesRaw.kiwi,
}
ThemesRaw.lemon.portal = {
  ...ThemesRaw.lemon,
  juicy: '#121217',
}

export const Themes = ThemesRaw
