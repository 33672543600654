import BigNumber from 'bignumber.js'
import _ from 'lodash'
import pluralize from 'pluralize'

import { Themes } from '@/Pages/App/Design/Themes.js'
import { useCheckoutState } from '@/Pages/Web/Components/Checkout/useCheckoutState'
import route from '@/Util/route.js'

const { state: checkoutState } = useCheckoutState()

export default {
  computed: {
    $can() {
      return this.$page?.props.can || {}
    },
    environment() {
      return this.$page?.props.app.environment
    },
    isDusk() {
      return this.$page?.props.app.is_dusk
    },
    isDarkMode() {
      /*return (
                localStorage.theme === 'dark' ||
                (!('theme' in localStorage) &&
                    window.matchMedia('(prefers-color-scheme: dark)').matches)
            );*/
      return false
    },
    isTestMode() {
      return this.$page?.props.isTestMode ?? checkoutState.value.isTestMode
    },
    assetUrl() {
      return this.$page?.props.app.assetUrl
    },
    currentDisk() {
      return this.$page?.props.app.currentDisk
    },
    currentUser() {
      return this.$page?.props.currentUser ?? checkoutState.value.currentUser
    },
    currentStore() {
      return this.$page?.props.currentStore ?? checkoutState.value.currentStore
    },
    currentThemeSwatches() {
      const theme = Themes[this.currentStore?.theme] || Themes.vanilla
      const colors = new Set(Object.values(theme.storefront))
      return Array.from(colors)
    },
    csrfToken() {
      return this.$page?.props.app.csrfToken
    },
    activity() {
      return this.$page?.props.activity
    },
    affiliateProfile() {
      return this.$page?.props.affiliateProfile
    },
    isAffiliatesHub() {
      return this.$page?.props.isAffiliatesHub
    },
  },

  methods: {
    asset(path) {
      return `${this.assetUrl}/${path}`
    },
    $route: (...args) => route(args[0], args[1], args[2]),
    isRoute(...routes) {
      const currentRoute = this.$route().current()

      return routes.some((route) => {
        if (!route) {
          return false
        }

        if (!route.endsWith('.*')) {
          return currentRoute === route
        }

        const namespace = route.substring(0, route.length - 2)

        return currentRoute === namespace || currentRoute.startsWith(namespace + '.')
      })
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    hasSlot(name = 'default') {
      return !!this.$slots[name]
    },
    orderStatus(order) {
      if (order.status === 'refunded' || order.status === 'partial_refund') {
        return 'reverse'
      }
      if (order.status === 'pending' || order.status === 'processing' || order.status === 'disputed') {
        return 'warning'
      }
      if (order.status === 'failed' || order.status === 'fraudulent' || order.status === 'chargeback') {
        return 'danger'
      }

      return 'success'
    },
    personStatus(person) {
      if (person.status === 'subscribed') {
        return 'success'
      }
      if (person.status === 'requires_verification') {
        return 'warning'
      }

      return 'danger'
    },
    broadcastStatus(broadcast) {
      if (broadcast.status === 'sent') {
        return 'success'
      }
      if (broadcast.status === 'sending' || broadcast.status === 'scheduled') {
        return 'warning'
      }

      return 'disabled'
    },
    affiliateStatus(affiliate) {
      if (affiliate.status === 'active') {
        return 'success'
      }
      if (affiliate.status === 'rejected') {
        return 'danger'
      }
      if (affiliate.status === 'pending') {
        return 'warning'
      }

      return 'expired'
    },
    affiliateReferralStatus(referral) {
      if (referral.status === 'pending') {
        return 'warning'
      }
      if (referral.status === 'unpaid') {
        return 'expired'
      }
      if (referral.status === 'rejected') {
        return 'danger'
      }

      return 'success'
    },
    formatNumber(number) {
      return new Intl.NumberFormat().format(number)
    },
    formatCurrency(amount, currency = 'USD', maximumFractionDigits = 14) {
      const value = BigNumber(amount).dividedBy(100)

      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: maximumFractionDigits,
      }).format(value)
    },
    formatError(error) {
      return error.replace(/\.\s*$/, '')
    },
    currencySymbol(currency) {
      const parts = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      }).formatToParts()
      const part = parts.find((part) => part.type === 'currency')

      return part.value || '$'
    },
    plural(string, count = 0, inclusive = false) {
      return pluralize(string, count, inclusive)
    },
    createAlert(message, variant = 'success') {
      this.eventHub.emit('create-alert', {
        variant: variant,
        message: message,
      })
    },
    scrollToFormError() {
      this.$nextTick(() => {
        const errorEls = document.querySelectorAll('.form-error')
        if (errorEls.length) {
          const target = errorEls[0].parentElement || errorEls[0]
          target.scrollIntoView({
            behavior: 'smooth',
          })
        }
      })
    },
    $clipboard(text) {
      return navigator.clipboard.writeText(text)
    },
    getOr(obj, path, def) {
      const val = _.get(obj, path)
      return _.isEmpty(val) ? def : val
    },
    isUrl(str) {
      try {
        new URL(str)
        return true
      } catch (err) {
        return false
      }
    },
  },

  created() {},
}
