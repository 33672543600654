<script setup lang="ts">
import ErrorLayout from '@/Layouts/ErrorLayout.vue'
import type { NuxtError } from '#app'
import type { FetchError } from 'ofetch';

const props = defineProps<{
  error: NuxtError | FetchError;
  requestId?: string
}>()

const title = computed(() => {
  return {
    503: '503: Service Unavailable',
    500: '500: Server Error',
    404: '404: Page Not Found',
    403: '403: Forbidden',
  }[props.error.statusCode!]
});

const description = computed(() => {
  return {
    503: 'Sorry, we are doing some maintenance. Please check back soon.',
    500: 'Whoops, something went wrong on our servers.',
    404: 'Sorry, the page you are looking for could not be found.',
    403: 'Sorry, you are forbidden from accessing this page.',
  }[props.error.statusCode!]
});
</script>

<template>
  <ErrorLayout>
    <div class="text-center">
      <h1 class="mb-2 text-24 font-medium dark:text-dark-95">
        {{ title }}
      </h1>

      <div>
        {{ description }}
        <p v-if="requestId">Request ID: {{ requestId }}</p>
      </div>
    </div>
  </ErrorLayout>
</template>
