import { ref, computed } from 'vue'

const state = ref({})
const isReady = ref(false)

// Global checkout state to replace Inertia's global props
export const useCheckoutState = () => {
  const setState = (value, setReady = true) => {
    state.value = {
      ...state.value,
      ...value,
    }

    if (value.cart.id && setReady) {
      isReady.value = true
    }
  }

  return {
    state: computed(() => state.value),
    setState,
    isReady,
  }
}
