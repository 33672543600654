import route from 'ziggy-js'
import { Ziggy } from '../ziggy.js'

export default function (name, params) {
  if(window.route) {
    return window.route(name, params);
  }

  return route(name, params, undefined, Ziggy)
}
