import { default as checkoutlZQLvi17ODMeta } from "/vercel/path0/pages/checkout.vue?macro=true";
export default [
  {
    name: checkoutlZQLvi17ODMeta?.name ?? "checkout",
    path: checkoutlZQLvi17ODMeta?.path ?? "/checkout",
    meta: checkoutlZQLvi17ODMeta || {},
    alias: checkoutlZQLvi17ODMeta?.alias || [],
    redirect: checkoutlZQLvi17ODMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout.vue").then(m => m.default || m)
  }
]