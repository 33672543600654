import * as Sentry from '@sentry/vue'
import sentryIgnoreErrors from '~/helpers/sentryIgnoreErrors'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: [window.location.origin, /https?:\/\/(.*?\.)?lemonsqueezy\.com/],
      }),
    ],
    tracesSampleRate: sentry.tracesSampleRate || 0.0,
    replaysSessionSampleRate: sentry.replaysSessionSampleRate || 0.0,
    replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate || 0.0,
    ignoreErrors: sentryIgnoreErrors,
    beforeSend: (event) => {
      const filename = event.exception?.values?.at(0)?.stacktrace?.frames?.at(0)?.filename

      if (filename?.includes('gtag/js')) {
        return null
      }

      return event
    },
  })
})
