import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (_routes) => [
    {
      name: 'home',
      path: '/',
      component: () => import('~/pages/checkout.vue').then((r) => r.default || r),
    },
    {
      name: 'checkout',
      path: '/checkout',
      component: () => import('~/pages/checkout.vue').then((r) => r.default || r),
    },
    {
      name: 'buy',
      path: '/buy/:variantId',
      component: () => import('~/pages/checkout.vue').then((r) => r.default || r),
    },
    // TODO. Remove this once both PR and CF worker are deployed
    {
      name: 'embed',
      path: '/buy/:variantId/embed',
      component: () => import('~/pages/checkout.vue').then((r) => r.default || r),
    },
    {
      name: 'customQuery',
      path: '/buy/:variantId/custom-query/:queryParams',
      component: () => import('~/pages/checkout.vue').then((r) => r.default || r),
      beforeEnter: (to, from) => {
        if (from.name === 'buy') {
          return false
        }
      },
    },
  ],
}
