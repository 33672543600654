<template>
  <div>
    <TestModeNotice v-if="isTestMode" />
    <div class="flex h-screen min-h-40 items-center justify-center bg-white text-body">
      <slot />
    </div>
  </div>
</template>

<script>
import TestModeNotice from '@/Layouts/Components/TestModeNotice.vue'

export default {
  components: {
    TestModeNotice,
  },
}
</script>
