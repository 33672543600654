import route, { type Config } from 'ziggy-js'
import { Ziggy } from '@/ziggy.js'

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useAppConfig()

  Ziggy.url = config.url

  function replaceDomain(domain: string) {
    const parts = domain.split('.')

    if (parts.length > 2 && !config.isDusk) {
      return `${parts[0]}.${config.domain}`
    } else {
      return config.domain
    }
  }

  for (const routeName in Ziggy.routes) {
    if (Ziggy.routes[routeName].domain) {
      Ziggy.routes[routeName].domain = replaceDomain(Ziggy.routes[routeName].domain)
    }
  }

  nuxtApp.vueApp.mixin({
    methods: {
      $route: (...args: any) => {
        return route(args[0], args[1], args[2], Ziggy as Config)
      },
    },
  })
})
