<script setup>
import FaviconLocal from '~/assets/icons/favicon-local.png?url'
import FaviconStaging from '~/assets/icons/favicon-staging.png?url'
import Favicon from '~/assets/icons/favicon.png?url'

const config = useAppConfig()

const favicons = {
  local: FaviconLocal,
  preview: FaviconStaging,
  development: FaviconStaging,
  production: Favicon,
}

const favicon = favicons[config.environment]

useHead({
  link: [{ rel: 'icon', type: 'image/png', href: favicon }],
})
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage page-key="static" />
  </NuxtLayout>
</template>
